@use "common/src/main" as common;

// universe colors
$default-background: var(--sapBaseColor);
$odd-row-background-color: var(--sapNeutralBackground);
$background-hover: var(--sapList_Hover_SelectionBackground);
$default-font-color: var(--sapTextColor);
$table-header-border-bottom-color: var(--sapList_HeaderBorderColor);
$separator: var(--sapTile_SeparatorColor);
$pager-button-background: var(--sapButton_Background);
$pager-button-size: 36px;

// common datatable colors
$ngx-datatable-background: $default-background !default;
$ngx-datatable-box-shadow: var(--sapContent_ShadowColor) !default;
$ngx-datatable-row-odd-background: $odd-row-background-color !default;

// default row and cell background colors
$ngx-datatable-default-background: $default-background !default;
$ngx-datatable-default-background-hover: $background-hover !default;
$ngx-datatable-default-background-focus: $background-hover !default;

// default background colors for cell selection style
$ngx-datatable-cellselection-background-hover: $background-hover !default;
$ngx-datatable-cellselection-background-focus: $background-hover !default;

// background and text colors for selected cell / row
$ngx-datatable-selected-active-background: $background-hover !default;
$ngx-datatable-selected-active-color: $default-font-color !default;
$ngx-datatable-selected-active-background-hover: $background-hover !default;
$ngx-datatable-selected-active-color-hover: $default-font-color !default;
$ngx-datatable-selected-active-background-focus: $background-hover !default;
$ngx-datatable-selected-active-color-focus: $default-font-color !default;

// colors for header elements
$datatable-header-cell-background: $default-background !default;
$datatable-header-cell-color: $default-font-color !default;
$datatable-header-border-bottom-color: $separator !default;
$datatable-header-resize-handle-color: $separator !default;

// colors for table body elements
$datatable-row-detail-background: $default-background !default;
$datatable-body-cell-color: $default-font-color !default;
$datatable-group-header-background: $default-background !default;
$datatable-group-header-border-top-color: $separator !default;
$datatable-group-header-border-bottom-color: $separator !default;

// colors for footer elements
$datatable-footer-cell-color: $default-font-color !default;
$datatable-footer-border-top-color: $separator !default;
$datatable-pager-color: $default-font-color !default;
$datatable-pager-color-hover: $default-font-color !default;
$datatable-pager-background-hover: $background-hover !default;
$datatable-pager-navigation-button-background: $pager-button-background !default;
$datatable-pager-disabled-opacity: var(--sapContent_DisabledOpacity) !default;
$datatable-pager-disabled-background: $pager-button-background !default;
$datatable-pager-active-background: $background-hover !default;

// colors for summary row elements
$datatable-summary-row-background: #ddd !default;
$datatable-summary-row-background-hover: #ddd !default;

.ngx-datatable.uni-datatable {
  background: $ngx-datatable-background;
  box-shadow: $ngx-datatable-box-shadow;

  &.striped {
    .datatable-row-odd {
      background-color: $ngx-datatable-row-odd-background;
    }

    &.reversed {
      background-color: $ngx-datatable-row-odd-background;

      .datatable-row-odd {
        background-color: $default-background;
      }
    }
  }

  &.single-selection,
  &.multi-selection,
  &.multi-click-selection {
    .datatable-body-row {
      &.active,
      &.active .datatable-row-group {
        background-color: $ngx-datatable-selected-active-background;
        color: $ngx-datatable-selected-active-color;
        outline: 1px solid var(--sapLinkColor, #4db1ff);
        outline-offset: -1px;
      }

      &.active:hover,
      &.active:hover .datatable-row-group {
        background-color: $ngx-datatable-selected-active-background-hover;
        color: $ngx-datatable-selected-active-color-hover;
      }

      &.active:focus,
      &.active:focus .datatable-row-group {
        background-color: $ngx-datatable-selected-active-background-focus;
        color: $ngx-datatable-selected-active-color-focus;
      }
    }
  }

  &:not(.cell-selection) {
    .datatable-body-row {
      &:hover,
      &:hover .datatable-row-group {
        background-color: $ngx-datatable-default-background-hover;
        transition-property: background;
        transition-duration: 0.3s;
        transition-timing-function: linear;
        cursor: pointer;
        border-bottom: 1px solid var(--sapLinkColor, #4db1ff);
      }

      &:focus,
      &:focus .datatable-row-group {
        background-color: $ngx-datatable-selected-active-background;
        color: $ngx-datatable-selected-active-color;
        outline: 1px solid var(--sapLinkColor, #4db1ff);
        outline-offset: -1px;
      }
    }
  }

  &.cell-selection {
    .datatable-body-cell {
      &:hover,
      &:hover .datatable-row-group {
        background-color: $ngx-datatable-cellselection-background-hover;
        transition-property: background;
        transition-duration: 0.3s;
        transition-timing-function: linear;
      }

      &:focus,
      &:focus .datatable-row-group {
        background-color: $ngx-datatable-cellselection-background-focus;
      }

      &.active,
      &.active .datatable-row-group {
        background-color: $ngx-datatable-selected-active-background;
        color: $ngx-datatable-selected-active-color;
      }

      &.active:hover,
      &.active:hover .datatable-row-group {
        background-color: $ngx-datatable-selected-active-background-hover;
        color: $ngx-datatable-selected-active-color-hover;
      }

      &.active:focus,
      &.active:focus .datatable-row-group {
        background-color: $ngx-datatable-selected-active-background-focus;
        color: $ngx-datatable-selected-active-color-focus;
      }
    }
  }

  // not any selection at all
  &:not(.cell-selection, .single-selection, .multi-selection, .multi-click-selection, .checkbox-selection) {
    .datatable-body-row {
      &:hover,
      &:hover .datatable-row-group {
        cursor: default;
      }
    }
  }

  /**
	 * Shared Styles
	 */
  .empty-row {
    height: 50px;
    text-align: left;
    padding: 0.5rem 1.2rem;
    vertical-align: top;
    border-top: 0;
  }

  .loading-row {
    text-align: left;
    padding: 0.5rem 1.2rem;
    vertical-align: top;
    border-top: 0;
  }

  /**
	 * Global Row Styles
	 */
  .datatable-header,
  .datatable-body {

    .datatable-row-left {
      background-color: $ngx-datatable-background;
      background-position: 100% 0;
      background-repeat: repeat-y;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQIHWPSkNeSBmJhTQVtbiDNCgASagIIuJX8OgAAAABJRU5ErkJggg==);
    }

    .datatable-row-right {
      background-position: 0 0;
      background-color: $ngx-datatable-background;
      background-repeat: repeat-y;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQI12PQkNdi1VTQ5gbSwkAsDQARLAIGtOSFUAAAAABJRU5ErkJggg==);
    }
  }

  /**
	 * Header Styles
	 */
  .datatable-header {
    border-bottom: 1px solid $datatable-header-border-bottom-color;

    .datatable-header-inner {
      display: flex;
      height: 100%;
      align-items: flex-end;
      padding-bottom: 7px;
    }

    .datatable-header-cell {
      font-weight: 700;
      font-size: 14px;
      //background-color: $datatable-header-cell-background;
      color: $datatable-header-cell-color;
      display: flex;
      align-items: center;

      span.sort-btn {
        font-family: 'SAP-icons', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        margin-left: 5px;

        &.datatable-icon-up.sort-asc {
          &:after {
            content: "";
          }
        }

        &.datatable-icon-down.sort-desc {
          &:after {
            content: "";
          }
        }
      }

      .datatable-header-cell-wrapper {
        padding-left: 16px;
        position: relative;
      }

      &.longpress {
        .draggable::after {
          transition: transform 400ms ease, opacity 400ms ease;
          opacity: 0.5;
          transform: scale(1);
        }
      }

      .draggable::after {
        content: ' ';
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -30px 0 0 -30px;
        height: 60px;
        width: 60px;
        background: #eee;
        border-radius: 100%;
        opacity: 1;
        filter: none;
        transform: scale(0);
        z-index: common.$popover-z-index;
        pointer-events: none;
      }

      &.dragging {
        .resize-handle {
          border-right: none;
        }
      }
    }

    .resize-handle {
      border-right: solid 1px $datatable-header-resize-handle-color;
    }
  }

  /**
	 * Body Styles
	 */
  .datatable-body {
    position: relative;
    //margin-bottom: 25px;

    .datatable-row-detail {
      background: $datatable-row-detail-background;
      //padding: 10px;
    }

    .datatable-group-header {
      background: $datatable-group-header-background;
      border-bottom: solid 1px $datatable-group-header-border-bottom-color;
      border-top: solid 1px $datatable-group-header-border-top-color;
    }

    .datatable-body-row {
      border-bottom: 1px solid transparent;


      .datatable-body-cell {
        text-align: left;
        padding: 10px 8px 10px 16px;
        vertical-align: top;
        border-top: 0;
        color: $datatable-body-cell-color;
        transition: width 0.3s ease;
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;

        // cell active class
        // &.active {
        //  background: #0829e0
        // }

        &.interactive {

          .datatable-body-cell-label {
            span {
              color: #29A1FF;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }

      .datatable-body-group-cell {
        text-align: left;
        padding: 14px 8px 14px 16px;
        vertical-align: top;
        border-top: 0;
        color: $datatable-body-cell-color;
        transition: width 0.3s ease;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .progress-linear {
      display: block;
      position: sticky;
      width: 100%;
      height: 5px;
      padding: 0;
      margin: 0;
      top: 0;

      .container {
        display: block;
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 5px;
        -webkit-transform: translate(0, 0) scale(1, 1);
        transform: translate(0, 0) scale(1, 1);
        background-color: rgb(170, 209, 249);

        .bar {
          transition: all 0.2s linear;
          -webkit-animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
          animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);

          transition: -webkit-transform 0.2s linear;
          transition: transform 0.2s linear;
          background-color: rgb(16, 108, 200);

          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 100%;
          height: 5px;
        }
      }
    }
  }

  /**
	 * Footer Styles
	 */
  .datatable-footer {
    font-size: 12px;
    font-weight: 400;
    color: $datatable-footer-cell-color;
    overflow-y: hidden;

    .page-count {
      display: none;
      line-height: 36px;
      height: 36px;
      padding: 0 1.2rem;
    }

    .datatable-pager {
      height: 100%;
      flex: 1 1 50%;

      li {

        // hiding the last and first arrow buttons
        &:last-child,
        &:first-child {
          display: none;
        }

        vertical-align: middle;

        a {
          height: $pager-button-size;
          width: $pager-button-size;

          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: $pager-button-size;

          border-radius: 8px;
          text-align: center;
          color: $datatable-pager-color;
          text-decoration: none;

          &:hover {
            color: $datatable-pager-color-hover;
            border: 1px solid var(--sapLinkColor, #4db1ff);
          }

          i {
            font-family: 'SAP-icons', serif;
            font-style: normal;

            &:after {
              display: inline-block;
            }

            &.datatable-icon-left:after {
              content: "";
            }

            &.datatable-icon-prev:after {
              content: "";
            }

            &.datatable-icon-right:after {
              content: "";
            }

            &.datatable-icon-skip:after {
              content: ""
            }

          }
        }

        &.pages {
          margin: 0 3px;
        }

        // Pager back and next buttons
        &:not(.pages):not(.active) {

          &:nth-last-child(2) {
            margin-left: 15px;
          }

          &:nth-child(2) {
            margin-right: 15px;
          }

          a {
            background-color: $datatable-pager-navigation-button-background;
          }
        }

        &.disabled a {
          opacity: $datatable-pager-disabled-opacity;
          background-color: $datatable-pager-disabled-background !important;
        }

        &.active a {
          //background-color: $datatable-pager-active-background;
          border: 1px solid var(--sapLinkColor, #4db1ff);
          border-radius: 6px;

          //font-weight: bold;
        }

      }
    }
  }

  // Summary row styles
  .datatable-summary-row {
    .datatable-body-row {
      background-color: $datatable-summary-row-background;

      &:hover {
        background-color: $datatable-summary-row-background-hover;
      }

      .datatable-body-cell {
        font-weight: bold;
      }
    }
  }
}

/**
 * Checkboxes
**/
.datatable-checkbox {
  position: relative;
  margin: 0;
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
  box-sizing: border-box;
  padding: 0;

  input[type='checkbox'] {
    position: relative;
    margin: 0 1rem 0 0;
    cursor: pointer;
    outline: none;

    &:before {
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      content: '';
      position: absolute;
      left: 0;
      z-index: 1;
      width: 1rem;
      height: 1rem;
      border: 2px solid #f2f2f2;
    }

    &:checked:before {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
      height: 0.5rem;
      border-color: #009688;
      border-top-style: none;
      border-right-style: none;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 1rem;
      height: 1rem;
      background: #fff;
      cursor: pointer;
    }
  }
}
