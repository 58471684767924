.custom-slider {
  .ngx-slider {
    margin: 12px 0 40px !important;

    .ngx-slider-bubble{
      top: 13px !important;
      color: white !important;
      font-size: 14px !important;
      font-weight: normal !important;
    }
    .ngx-slider-pointer {
      background-color: #29313a !important;
      width: 20px !important;
      height: 20px !important;
      top: -9px !important;
      border: 1px solid #8696a9;
    }
    .ngx-slider-pointer:after {
      display: none;
    }
    .ngx-slider-bar {
      background: #8696a9 !important;

      .ngx-slider-left-out-selection {
        background: #91c8f6 !important;
      }
    }
    &[disabled] {
      .ngx-slider-bar {
        background: #6a6d70 !important;
      }
      .ngx-slider-bubble{
        color: #6a6d70 !important;
      }
    }
  }
}


