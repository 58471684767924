@mixin uni-loader-spinner-component($config) {
  $uni-loader-spinner-component-config: map-merge(
    (
      "uni-loader-spinner-z-index": null,
      "uni-loader-spinner-indicator-height": null
    ),
    $config
  );

  uni-loader-spinner {
    .loading-backdrop {
      z-index: map-get($uni-loader-spinner-component-config, "uni-loader-spinner-z-index");

      fd-busy-indicator {
        height: map-get($uni-loader-spinner-component-config, "uni-loader-spinner-indicator-height");
      }
    }
  }
}
