formly-field {

  &:not(.no-gap):not(:last-child) {
    display: block;
    margin-bottom: 1.5rem;

    &:empty {
      margin-bottom: 0;
    }
  }
  textarea.formly-text-area,
  input.fd-input {
    margin-top: 0;
    margin-bottom: 0;
  }
}
