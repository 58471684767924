:root {
  // WCAG temp fix for evening horizon theme
  // @sap-theming/theming-base-content: https://github.com/SAP/theming-base-content/issues/14
  // fundamental-ngx: https://github.com/SAP/fundamental-ngx/issues/9266
  --sapButton_Emphasized_TextColor: #ffffff;
}

.fd-button.is-focus.fd-button--toggled,
.fd-button.is-focus.is-selected,
.fd-button:focus.is-selected {
  outline-color: var(--fdButton_Outline_Contrast);
  outline: var(--sapContent_FocusWidth) var(--sapContent_FocusStyle) var(--sapContent_FocusColor);
}
