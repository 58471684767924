@mixin uni-widget-refresh-component($config) {
  $uni-widget-refresh-component-config: map-merge(
    (
      "uni-widget-refresh-top": 8px,
      "uni-widget-refresh-right": 8px,
      "uni-widget-refresh-z-index": null,
    ),
    $config
  );

  .widget-refresh-btn {
    top: map-get($uni-widget-refresh-component-config, uni-widget-refresh-top);
    right: map-get($uni-widget-refresh-component-config, uni-widget-refresh-right);
    z-index: map-get($uni-widget-refresh-component-config, uni-widget-refresh-z-index);
  }
}
