div.fd-message-strip-alert-overlay--middle {
  left: 10vw;
  width: 80vw;
  @media screen and (max-width: 992px) {
    left: 5vw;
    width: 90vw;
  }
}

div.fd-message-strip-alert-overlay {
  max-width: 80vw;
  @media screen and (max-width: 992px) {
    max-width: 90vw;
  }
}