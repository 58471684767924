@mixin icon-fill($color) {
  .btn-three-dots__icon {
    fill: $color;
  }
}

@mixin uni-three-dots-component($config) {
  $component-config: map-merge(
    (
      "uni-three-dots-hover-background-color": null,
      "uni-three-dots-hover-border": null,
      "uni-three-dots-hover-box-shadow": null,
      "uni-three-dots-hover-icon-fill": var(--sapButton_Lite_Hover_TextColor),
      "uni-three-dots-icon-fill": var(--sapButton_IconColor),
      "uni-three-dots-disabled-icon-fill": #707579,
      "uni-three-dots-active-icon-fill": var(--sapButton_Active_TextColor)
    ),
    $config
  );

  .btn-three-dots {
    &:hover {
      background-color: map-get($component-config, "uni-three-dots-hover-background-color");
      border: map-get($component-config, "uni-three-dots-hover-border");
      box-shadow: map-get($component-config, "uni-three-dots-hover-box-shadow");

      @include icon-fill(map-get($component-config, "uni-three-dots-hover-icon-fill"));
    }

    @include icon-fill(map-get($component-config, "uni-three-dots-icon-fill"));

    &:disabled {
      @include icon-fill(map-get($component-config, "uni-three-dots-disabled-icon-fill"));
    }

    &:active {
      @include icon-fill(map-get($component-config, "uni-three-dots-active-icon-fill"));
    }
  }
}
