.purpose-dialog__select-body {
  width: 616px !important;
  max-width: 616px !important;
}

.fd-select-options.fd-list {
  .pallet-container {
    .fd-list__item.is-focus:before {
      border: 1px dotted #fff;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

fd-multi-input {
  input.fd-tokenizer__input:disabled {
    opacity: 1;
  }
}
