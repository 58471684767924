/* You can add global styles to this file, and also import other style files */
@use "common/src/main" as common;
@import "styles/spacing";
@import "styles/flex";
@import "styles/text-directions";
@import "styles/button";
@import "styles/select";
@import "styles/side-nav";
@import "styles/dragula";
@import "styles/slider.scss";
@import "styles/commons";
@import "styles/menu.scss";
@import "styles/datatable";
@import "styles/uni-card";
@import "styles/connector-card";
@import "styles/token";
@import "styles/link";
@import "styles/date-range";
@import "styles/formly-fields";
@import "styles/fd-message-strip-alert";

@font-face {
  src: url('/resources/72-Regular-full.woff2') format('woff2'),
  url('/resources/72-Regular-full.woff') format('woff');
  font-family: '72';
  font-weight: 400; // normal
  font-style: normal;
}

@font-face {
  src: url('/resources/72-Light.woff2') format('woff2'),
  url('/resources/72-Light.woff') format('woff');
  font-family: '72';
  font-weight: 300; // light
  font-style: normal;
}

@font-face {
  src: url('/resources/72-Light.woff2') format('woff2'),
  url('/resources/72-Light.woff') format('woff');
  font-family: '72-Light';
  font-weight: 300; // light
  font-style: normal;
}

@font-face {
  src: url('/resources/72-Bold.woff2') format('woff2'),
  url('/resources/72-Bold.woff') format('woff');
  font-family: '72';
  font-weight: 700; // bold
  font-style: normal;
}

@font-face {
  src: url('/resources/72-Bold.woff2') format('woff2'),
  url('/resources/72-Bold.woff') format('woff');
  font-family: '72-Bold';
  font-weight: 700; // bold
  font-style: normal;
}

@font-face {
  src: url("/resources/SAP-icons.woff2") format("woff2"),
  url("/resources/SAP-icons.woff") format("woff"),
  url("/resources/SAP-icons.ttf") format("truetype");
  font-family: "SAP-icons";
  font-weight: 400; // normal
  font-style: normal;
}

@font-face {
  src: url("/resources/BusinessSuiteInAppSymbols.woff2") format("woff2"),
  url("/resources/BusinessSuiteInAppSymbols.woff") format("woff"),
  url("/resources/BusinessSuiteInAppSymbols.ttf") format("truetype");
  font-family: "BusinessSuiteInAppSymbols";
  font-weight: normal;
  font-style: normal;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  // qualtrics modal adds "overflow: auto;" inline style to the body element after closing it
  overflow: hidden !important;
}

// RULES TO COVER FIORI BUGS

.fd-list__item.fd-multi-input-show-all {
  display: none;
}

fd-menu {
  padding: 0 !important;
}

body {
  color: var(--sapTextColor);
  font-family: var(--sapFontFamily, "72");
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

//GLOBAL GENERIC STYLES

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

//END GLOBAL
app-wrapper {
  display: block;
  overflow-x: auto;
  height: 100%;
  overflow-y: hidden;
}

app-root {
  width: 100%;
  height: 100%;
}

.top-bar {
  border-bottom: 1px solid var(--sapTile_SeparatorColor);
}

.list-card {
  position: relative;
  border-radius: 16px !important;
  background: var(--sapTile_Background);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--sapTile_SeparatorColor);
  box-sizing: border-box;

  call-to-action-ui-decoration {
    fd-icon:before {
      color: var(--universeIconColor, #d1e8ff);
    }
  }

  &.interactive {

    &:hover,
    &:focus-visible {

      box-shadow: 0 0 0 0.0625rem var(--fdGrid_List_Item_Hover_Border_Color, var(--sapTile_Interactive_BorderColor, #b3b3b3));
      background-color: var(--sapList_Hover_Background, #f5f5f5);
      cursor: pointer;

      .call-to-action-ui-decoration {
        fd-icon:before {
          color: var(--sapLink_Hover_Color, #4db1ff);
        }
      }
    }

    &:active {
      background: var(--sapTile_Active_Background);

      &:before {
        border: var(--sapContent_FocusWidth) var(--sapContent_FocusStyle) var(--sapContent_FocusColor);
        border-radius: var(--fdCard_Border_Radius) var(--fdCard_Border_Radius) var(--fdCard_Border_Radius) var(--fdCard_Border_Radius);
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 3;
      }
    }
  }
}

.card {
  width: 100%;
  height: 100%;
  background: var(--sapTile_Background);
  padding: 1rem;
  color: var(--sapTextColor);
  position: relative;
  border-radius: 16px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--sapTile_SeparatorColor) !important;
  box-sizing: border-box;
}

.card-title {
  font-weight: 300;
  font-size: 20px;
  line-height: 23px;
  color: var(--sapTextColor);
}

.card-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--sapTextColor);
}

.section-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: var(--sapTextColor);
}

.outline:focus-visible,
.outline-hover:hover,
.outline-focus:focus,
  /*button.outline:focus*/
{

  outline: var(--sapContent_FocusWidth) var(--sapContent_FocusStyle) var(--sapContent_FocusColor) !important;
  outline-offset: 0 !important;

  $min-offset: -4;
  $max-offset: 4;
  @mixin outline-offset-x {
    @for $i from $min-offset through $max-offset {
      @if $i < 0 {
        &.outline-offset-minus#{$i} {
          outline-offset: $i * 1px !important;
        }
      } @else {
        &.outline-offset-#{$i} {
          outline-offset: $i * 1px !important;
        }
      }
    }
  }

  @include outline-offset-x();
}

fd-select.fd-select {
  margin: 0;
}

div.fd-select__control button.fd-button {
  height: 100%;
}

input.fd-input[readonly]::placeholder, input.fd-input.is-disabled::placeholder, input.fd-input:disabled::placeholder, input.fd-input[aria-disabled=true]::placeholder {
  opacity: 1;
  color: var(--sapContent_LabelColor);
}

.fd-input-group,
.fd-select__control {
  margin: 0 !important;
}

//QuickFix to archieve the 100% width of fd-select
.fd-form-item {
  .fd-select-custom-class,
  .fd-combobox-custom-class,
  .fd-multi-input-custom {
    .fd-popover-custom {
      width: 100%;
    }
  }
}

.fd-popover-custom {
  display: block !important;
}

// Legacy components variables to match dark theme
:root {

  --universeActiveOutlineColor: #f7f7f7;
  --universeHorizontalLineColor: #8497a4;
  --universeTreeLinesColor: #7996b4;
  --universeUniTreeNodeMargin: 12px;
  --universeUniTreeNodePadding: 30px;
  --sapButton_Emphasized_Hover_Background: #085caf;
  --universeSplitterColor: #647887;
  --universeConnectionIndicatorColor: #5899da;
  --universePanelsBorderColor: #323e49;
  --universePanelsBackGroundColor: #283848;

  // Main Button
  --universeButtonMainBorderColor: #0a6ed1;
  --universeButtonMainBackgroundColor: #0a6ed1;

  // Secondary Button
  --universeButtonSecondaryTextColor: #d1e8ff;
  --universeButtonSecondaryBorderColor: #647887;
  --universeButtonSecondaryHoverBorderColor: #0a6ed1;
  --universeButtonSecondaryHoverBackgroundColor: #0a6ed1;

  // Input
  --universeInputTextColor: #fafafa;
  --universeInputBorderColor: #647887;
  --universeInputHoverBorderColor: #0a6ed1;
  --universeInputErrorBorderColor: #ff8888;

  // Select
  --universeSelectArrowColor: #d1e8ff;
  --universeSelectBorderColor: #647887;
  --universeSelectFocusOutlineColor: #fafafa;

  // Segmented Button
  --universeSegmentedButtonTextColor: #f7f7f7;
  --universeSegmentedButtonHoverBackgroundColor: #0a6ed1;

  // Tabs
  --universeTabsTextColor: #fafafa;
  --universeTabsHoverTextColor: #0080ff;
  --universeTabsHoverBorderColor: #0080ff;
  --universeTabsSelectedBackgroundColor: #d1e8ff;
  --universeTabsSelectedTextColor: #354a5f;
  --universeTabsSelectedHoverBackgroundColor: #0080ff;

  // Popover
  --universePopoverBackgroundColor: var(--sapBaseColor);
  --universePopoverArrowBorderColor: var(--sapTile_SeparatorColor);

  // Dark Popover
  --universeDarkPopoverBackgroundColor: #1d232a;

  --univreseDarkPopoverBoxShadow: 0 10px 30px 0 rgba(0, 0, 0, 0.46), 0 0 0 1px rgba(184, 150, 150, 0),
  0 0 0 1px rgba(100, 120, 135, 0.54); // keeping the typo for backward compatibility

  // Dark Menu
  --universeDarkMenuHoverColor: #0a6ed1;

  // Profile Menu
  --universeProfileMenuWidth: 200px;

  //Token Colors
  --universeTokenPink: #ff8888;

  //warning text color
  --text-warning: #FFDF72;
}

.flow-column {
  flex-direction: column;
}

.app-container {
  display: flex;
  flex-direction: column;
  margin: 0;
}

h2.page-header {
  background: #29313a;
  color: #d1e8ff;
  padding: 10px 20px;
  line-height: 1.5;
  font-size: 32px;
  font-weight: 400;
  margin: 0 !important;
}

.bg-dark-blue {
  background: #29313a;
}

.uni-panel {
  padding: 15px;
}

// ICON
.fd-nested-list__icon {
  color: var(--sapTextColor) !important;
}

.fd-nested-list__icon::before {
  font-size: 16px !important;
}

.fd-bar--footer:not(.fd-dialog__footer) {
  border-top: solid 1px #516677;
  margin-top: 25px;
  background-color: transparent !important;

  .btn-cancel {
    background: no-repeat;
    border: none;
    font-weight: bold;
  }

  .actions {
    color: var(--sapShell_InteractiveTextColor) !important;
  }
}

//.description-popover {
//  .fd-popover__popper .fd-popover__arrow:after {
//    border-top-color: var(--sapShell_InteractiveTextColor, #d1e8ff) !important;
//  }
//
//  .form-container {
//    padding: 15px;
//  }
//
//  .fd-popover-container-custom {
//    background-color: var(--sapShell_InteractiveTextColor, #d1e8ff) !important;
//    color: #0854a1;
//    padding: 10px;
//    //min-width: 221px;
//    //min-height: 103px;
//    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.3), 0 0 0 0 rgba(0, 0, 0, 0.15);
//    white-space: inherit;
//  }
//}


// Horizontal line
hr {
  background-color: var(--universeHorizontalLineColor);
  color: var(--universeHorizontalLineColor);
  height: 1px;
  border: none;
}

// Tree
.tree-node-wrapper {
  list-style-type: none;
}

//// Leader Line (Mapping line)
//.leader-line {
//  defs .leader-line-line-path {
//    pointer-events: all !important;
//  }
//
//  g use:hover {
//    stroke: #8ac5ff !important;
//    cursor: pointer !important;
//  }
//}

// Monaco Editor

.editor-container {
  height: 100% !important;

  .monaco-editor .selected-text {
    background-color: var(--sapLinkColor);
    opacity: 0.4;
  }

  .monaco-editor,
  .monaco-editor-background,
  .monaco-editor .inputarea.ime-input {
    background-color: var(--sapBackgroundColor);
    border: 1px solid var(--sapTile_SeparatorColor);
  }

  .monaco-editor .view-overlays .current-line {
    border: 2px solid var(--sapNeutralBorderColor);
  }

  .mtk1 {
    color: var(--sapTextColor);
  }

  .mtk2 {
    color: var(--sapTextColor);
  }

  .mtk3 {
    color: var(--sapChart_Sequence_10);

  }

  .mtk4 {
    color: var(--sapAvatar_7_TextColor);
  }

  .mtk5 {
    color: var(--sapAvatar_9_TextColor);
  }

  .mtk6 {
    color: var(--sapChart_Sequence_3);
  }

  .mtk7 {
    color: var(--sapChart_Sequence_7);
  }

  .mtk8 {
    color: var(--sapChart_Sequence_11);
  }

  .mtk9 {
    color: var(--sapContent_LabelColor);
  }

  .monaco-editor {
    .margin {
      background-color: var(--sapBaseColor, #1d232a);
    }

    .scroll-decoration {
      left: 64px;
    }
  }
}

app-data-event-wizard,
lib-edit-processing-purpose,
lib-data-event-wizard {
  .fd-tabs {
    background-color: var(--sapBaseColor) !important;
  }
}

// dialog
.cdk-drag-disabled {
  cursor: initial !important;
}

fd-dialog {
  z-index: common.$dialogs-z-index !important;
}

// mitigating fundamental issue https://github.com/SAP/fundamental-ngx/issues/9180
.cdk-overlay-container {
  z-index: common.$popover-z-index !important;
}

.uni-dialog {
  > div:first-child {
    border-radius: 5px;
    box-shadow: 0 5px 17px 0 rgba(0, 0, 0, 0.25), inset 0 0 0 0 #181818;
    background-color: var(--sapBaseColor);
  }

  fd-dialog-body,
  header {
    background: var(--sapBaseColor);
    display: flex;
    flex-direction: column;
  }

  header div[fd-bar-right] {
    display: none;
  }

  fd-dialog-body {
    padding: 15px;
  }
}

// custom scrollbar
.fd-list,
.fd-side-nav,
.fd-textarea,
.fd-popover__popper,
.fd-dialog__content,
.uni-scrollbar-tight {
  @extend %style-scroll-tight;
}

.style-scroll, // keep this rule for backward compatibility - please use `.uni-scrollbar` instead
.uni-scrollbar {
  @extend %style-scroll;
}

.uni-scrollbar {
  overflow-x: hidden;
}

lib-audience,
lib-customer-schema-setup,
lib-matching-rules,
lib-customer,
lib-account,
lib-events-playground,
lib-actions-playground,
.fd-popover__popper--cdk-custom {
  fd-tab-list {
    background-color: var(--sapBaseColor);

    .fd-tabs,
    [class="fd-tabs--*"] {
      background-color: var(--sapBaseColor);
      border-bottom: 1px solid var(--sapTile_SeparatorColor);
      background-color: inherit;
      padding: 0;
    }
  }
}

lib-matching-rules {
  fd-tab-list {
    .fd-tabs {
      padding: 0 !important;
      margin: 0 48px;
    }
  }

  @media (max-width: common.$max-width-L) {
    fd-tab-list {
      .fd-tabs {
        padding: 0 !important;
        margin: 0 32px;
      }
    }
  }
}


lib-audience,
lib-matching-rules {
  fd-tab-list {
    background-color: var(--sapBaseColor);
  }
}

.uni-shell .fd-shellbar {
  padding: 0 52px 0 45px;
  box-shadow: inset 0 -0.0625rem 0 0 var(--sapShellColor);
}

#edit-application-dialog {
  .fd-bar--header {
    box-shadow: none;
    border-bottom: 2px solid;
    border-color: var(--sapTile_SeparatorColor);
  }

  .fd-bar--footer {
    box-shadow: none;
    border-top: 2px solid;
    border-color: var(--sapTile_SeparatorColor);
    background-color: var(--sapBaseColor);
  }
}

.required-field-asterisk {
  pointer-events: none;
  font-size: var(--sapFontLargeSize);
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: var(--sapField_InvalidColor, #fa6161);
}

.chips {
  width: 85px;
  height: 26px;
  border-radius: 4px;
  border: solid 1px #5a6e7e;
  background-color: #132a42;
  margin-right: 8px;
  padding: 2.5px 8px;
}

.wizard-settings-popover:not(.wizard-settings-purposes) {
  padding: 15px;
}

*:focus {
  outline-width: 0;
}

.fd-dialog__content {
  box-shadow: none !important;
}

ng-scrollbar {
  --scrollbar-size: 10px !important;
  --scrollbar-thumb-color: #647887 !important;
  --scrollbar-thumb-hover-color: #647887 !important;
  --scrollbar-border-radius: 0px !important;
  width: 100% !important;
  height: 100% !important;
}

#uni-main-panel-header {
  position: relative !important;
  z-index: 1 !important;
}

.fd-tabs {
  position: relative !important;
  z-index: 1 !important;
  box-shadow: none !important;
  background-color: var(--sapBaseColor) !important;
  padding-left: common.$sap-margin-large !important;
}

.ng-scroll-viewport {
  &.customer-tab {
    padding: 27px 64px 0;
  }

  &.matching-rules {
    //padding: 32px 64px 72px;
  }
}

.no-left-padding {
  .matching-rules {
    padding-left: 0 !important;
    padding-top: 0 !important;
  }
}

.engagement-popover-title {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 8px;
  display: inline-block;
}

.engagement-popover-body {
  font-weight: 300;
  font-size: 14px;
  color: #fff;
  margin-bottom: 4px;

  .dot {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    display: inline-block;
  }

  .name {
    margin-left: 8px;
  }

  .dot,
  .name {
    vertical-align: middle;
  }
}

.engagement-popover {
  position: absolute;
  pointer-events: none;
  transition-duration: 0.2s;
  transition-property: transform;
  border-radius: 4px;
  background-color: #132a42;
  padding: 7px 25px 8px 13px;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.46), 0 0 0 1px rgba(184, 150, 150, 0), 0 0 0 1px rgba(100, 120, 135, 0.54);
  width: auto;

  &:before {
    position: absolute;
    content: "";
    left: 14px;
    bottom: -11px;
    border-style: solid;
    border-width: 11px 11px 0 11px;
    border-color: #374757 transparent transparent transparent;
  }

  &:after {
    position: absolute;
    content: "";
    left: 15px;
    bottom: -10px;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #132a42 transparent transparent transparent;
  }
}

.engagement-popover-vertical-line {
  position: absolute;
  bottom: -8px;
  left: 24px;
  width: 1px;
  transition: height 0.1s;
  background-color: #0a6ed1;
  transform: translateY(100%);

  &.enabled {
    &:after {
      content: "";
      width: 10px;
      height: 10px;
      background-color: #0a6ed1;
      position: absolute;
      bottom: -5px;
      left: -5px;
      border-radius: 50%;
    }
  }
}

.edit-processing-purpose-tab-list ul {
  padding-left: calc(64px - 0.1875rem);
  background-color: #354a5f;
}

// Administration page
lib-permissions-picker fd-multi-input input[type="text"] {
  color: transparent !important;
}

.uni-missing-permissions {
  cursor: not-allowed !important;

  * {
    pointer-events: none !important;
  }
}

.timeline {
  .fd-popover-container {
    background: #142637 !important;
  }
}

.color-selection {
  fd-popover-container > div {
    background: #142637;

    .pallet-container {
      display: inline-block;
      padding: 16px;
      width: 136px;
      height: 129px;
      flex-wrap: wrap;
      justify-content: center;
      background: #142637;
      border-bottom: 1px solid #3a4552;
      margin-bottom: 5px;
    }

    .fd-list--dropdown .fd-list__item:not(.more-colors) {
      width: 24px !important;
      float: left;
      height: 24px !important;
      border-radius: 3px !important;
      border: 2px solid transparent;
      margin: 5px;
      padding: 0 !important;

      &:hover {
        border-color: #91c8f6;
      }
    }

    .more-colors {
      background-color: #354a5f;
      padding: 6px 47px 6px 15px;

      span {
        font-size: 14px;
        color: #fafafa;
        font-family: var(--sapFontFamily, "72");
      }
    }
  }
}

.icon-selection {
  fd-popover-container > div {
    background: var(--sapBaseColor);

    .icon-container {
      display: inline-block;
      padding: 16px;
      width: 136px;
      height: 129px;
      flex-wrap: wrap;
      justify-content: center;
      background: var(--sapBaseColor);
      border-bottom: 1px solid var(--sapTile_SeparatorColor);
      margin-bottom: 5px;
    }

    .fd-list--dropdown .fd-list__item {
      float: left;
      /* border-radius: 3px !important; */
      /* border: 2px solid transparent; */
      margin: 5px;
      padding: 0 !important;
      display: flex;
      width: 24px;

      fd-icon {
        margin: auto;

        &:before {
          font-size: 18px;
        }
      }

      &:hover {
        border-color: #91c8f6;
        background: none;
      }
    }
  }
}

.uni-display-none {
  display: none !important;
}


// Hidden text for screen readers
.sr-only {
  display: block;
  position: absolute;
  left: -10000px !important;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

// formly-wrapper styles
app-formly-object .fd-panel__content {
  overflow: visible;
  //margin-bottom: 16px;
}

app-formly-array .fd-panel__content {
  overflow: visible;
}

.timeline {
  .fd-popover__popper .fd-popover__arrow {
    border-left-color: #132a42 !important;
  }
}

fd-popover-body.joule-popover-body {
  .fd-popover__body:before {
    border-bottom: calc(0.5rem - var(--fdPopover_Border_Width)) solid var(--sapContent_Illustrative_Color1);
  }
}

.admin-scope-picker-container__form-container {
  background-color: var(--sapBaseColor);
  padding: 15px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .fd-form-item {
    flex-basis: 48%;
    min-width: 200px;
  }
}

#permissions-multiselect {
  .fd-tokenizer-more {
    display: none !important;
  }
}

.permissions-multiselect-popover {

  .fd-list__item {
    padding-left: calc(2.75rem + 0.6875rem) !important;
  }

  .fd-popover__popper {
    border: 1px solid var(--sapTile_SeparatorColor);
    border-radius: 0 0 var(--sapElement_BorderCornerRadius, 0.25rem) var(--sapElement_BorderCornerRadius, 0.25rem);
    position: relative;
    top: 1px;
  }
}

.table-row {
  .fd-col {
    margin: 0 !important;
    padding: 0 !important;
  }

  .sap-icon--slim-arrow-down {
    font-size: 0.75rem !important;
    margin-left: 4px;
  }
}

fd-dialog-footer-button button.btn-cancel {
  &:hover {
    color: #91c8f6 !important;
    border: 1px solid #91c8f6 !important;
    border-radius: 4px;
  }

  &:focus {
    color: #91c8f6 !important;
    border: 1px solid #91c8f6 !important;
    border-radius: 4px;
  }

  &:active {
    background: #d1e8ff !important;
    border: 1px solid #d1e8ff !important;
    border-radius: 4px;
    color: #232a31 !important;
  }

  &:disabled {
    color: #707579 !important;
  }
}

fd-dialog-header a.btn-close {
  &:hover fd-icon:before {
    color: #0080ff;
  }

  &:focus fd-icon:before {
    color: #0080ff;
  }

  &:active fd-icon:before {
    color: #0854a0;
  }

  &:disabled fd-icon:before {
    color: #707579;
  }
}


color-picker {
  .type-policy-arrow {
    &:focus {
      outline: 1px dashed var(--universeButtonMainBackgroundColor);
    }
  }
}

uni-configure-action-purposes-dialog {
  .fd-busy-indicator {
    display: flex;
  }
}

.audience__dialog-header header,
.relationships__dialog-header header {
  background: var(--universePopoverBackgroundColor);
  border-bottom: 1px solid #516677;
}

.datacenter-icon {
  border: 1px solid #8696a9;
  border-radius: 2px;
}

.fd-button--compact {
  min-width: max-content;

  &:focus {
    outline: 1px dashed var(--universeButtonMainBackgroundColor);
  }
}

.shellbar__visible-overflow {
  overflow: visible;

  .fd-shellbar__subtitle {
    overflow: visible;
  }
}

.side-menu-popover-body.export-activities {
  left: 0px !important;
  top: -52px !important;

  #menu-body .list {
    height: 544px;
  }
}

.side-menu-popover-body.events-playground-flow-chart {

  #menu-body .list {
    height: calc(100vh - 240px);
  }
}

.user-menu {
  .fd-menu {
    width: 200px;
    margin-top: 10px;
  }

  .root-list-item {
    text-align: right;
  }

  .nested-list-item {
    text-align: left;
  }

  .fd-menu__sublist {
    left: initial;
    right: 100%;
  }
}

.fd-inline-help__content {
  padding: 0.5rem !important;
}

fd-dialog-container:not(:first-of-type) {
  .fd-dialog {
    &:before {
      opacity: var(--fdDialog_Background_Opacity, .6) !important;
    }
  }
}

.fd-segmented-button:before {
  z-index: 0 !important;
}

.progress-label-indicator {
  max-height: 24px;
  max-width: 111px;
  background-color: var(--sapTile_SeparatorColor);
  padding: 5px 0;
  border-radius: 6px;
  text-align: center;
  width: 118px;
  margin-right: 30px;
  text-transform: uppercase;
  font-size: 12px;
}

fd-step-input.uni-step-input {
  width: 128px;
}

div.fd-popover__body.pop, div.fd-popover__body.info-popover {
  padding: 8px;
  max-width: max(300px, 30vw);
}

div.fd-popover__body {
  height: auto;
  white-space: pre-line;
}

.fd-popover__body {
  &.list__no-rounded-corners {
    .fd-list__item:first-child,
    .fd-list__item:first-child:before,
    .fd-list__item:last-child,
    .fd-list__item:last-child:before {
      border-radius: 0 !important;
    }
  }
}

fd-tab-list.fd-tabs-custom {
  height: initial;
  flex: 1;
}

.fd-radio {
  &__label {
    &::after {
      position: absolute !important;
      top: 50% !important;
      transform: translateY(-50%);
    }
  }
}

.sap-tile-text-color {
  color: var(--sapTile_TextColor);
}

.sap-tile-title-text-color {
  color: var(--sapTile_TitleTextColor);
}

textarea.fd-textarea {
  pointer-events: auto !important;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}

fd-tab-list[ng-reflect-mode="process"] {
  .fd-tabs--process {
    .fd-tabs__icon {
      margin: 0;
    }

    .fd-tabs__item {
      margin-right: 0;
    }
  }

  .fd-tabs__process-icon {
    padding: 0;
  }

  .fd-tabs .fd-overflow-layout__items-container .fd-tabs__item:first-child {
    margin-left: 0;

    .fd-tabs__link {
      gap: 12px;
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }
}

 [fd-scrollbar].fd-scrollbar.is-focus, [fd-scrollbar].fd-scrollbar:focus {
   z-index: initial;
 }

// placing Joule on top of everything
#cai-webclient-main {
  z-index: common.$max-z-index !important;
}

.full-height {
  height: 100%;
}
