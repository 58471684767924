#app-side-nav {
  .fd-side-nav {
    position: fixed;
    top: var(--sapElement_LineHeight);
    height: calc(100vh - var(--sapElement_LineHeight)) !important;
    overflow-y: auto;
  }

  nav.fd-side-nav--condensed {
    overflow: hidden;
  }
}
