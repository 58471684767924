@forward "./lib/components/main";
@forward "./var";
@forward "./lib/services//dialog//api-dialog-manager.service.scss";
@use "@fundamental-styles/common-css/dist/sass/common-mixins" as sapMixin;
@forward "@fundamental-styles/common-css/dist/sass/common-mixins";

//region common-mixins facade

$sap-parameter-tiny: 'tiny';
$sap-parameter-small: 'small';
$sap-parameter-medium: 'medium';
$sap-parameter-large: 'large';

$sap-margin-tiny: map-get(sapMixin.$sap-margins, $sap-parameter-tiny);
$sap-margin-small: map-get(sapMixin.$sap-margins, $sap-parameter-small);
$sap-margin-medium: map-get(sapMixin.$sap-margins, $sap-parameter-medium);
$sap-margin-large: map-get(sapMixin.$sap-margins, $sap-parameter-large);

$sap-padding-tiny: map-get(sapMixin.$sap-paddings, $sap-parameter-tiny);
$sap-padding-small: map-get(sapMixin.$sap-paddings, $sap-parameter-small);
$sap-padding-medium: map-get(sapMixin.$sap-paddings, $sap-parameter-medium);
$sap-padding-large: map-get(sapMixin.$sap-paddings, $sap-parameter-large);

$sap-flex-spacing-tiny: map-get(sapMixin.$sap-flex-spacings, $sap-parameter-tiny);
$sap-flex-spacing-small: map-get(sapMixin.$sap-flex-spacings, $sap-parameter-small);
$sap-flex-spacing-medium: map-get(sapMixin.$sap-flex-spacings, $sap-parameter-medium);
$sap-flex-spacing-large: map-get(sapMixin.$sap-flex-spacings, $sap-parameter-large);

//endregion


$breadcrumbs-height: 68px;
$shell-bar-height: 54px;

$base-z-index: 1002;

$side-menus-z-index: $base-z-index + 10;
$main-nav-z-index: $side-menus-z-index + 10;
$loaders-z-index: $main-nav-z-index + 10;
$dialogs-z-index: $loaders-z-index + 10;
$popover-z-index: $dialogs-z-index + 10;
$drag-z-index: $popover-z-index + 10;
$main-nav-expanded-z-index: $popover-z-index + 10;
$skip-to-main-content-z-index: $main-nav-expanded-z-index + 10;
$drawer-backdrop-z-index: $main-nav-expanded-z-index + 10;
$drawer-z-index: $drawer-backdrop-z-index + 10;
$max-z-index: 999999999999;

$compact-uni-tree-width: 341px;

$max-width-S: 599px;
$max-width-M: 1023px;
$max-width-L: 1439px;
$max-width-XL: 1919px;

$search-bar-gap-full: 32px;
$search-bar-gap-compact: 8px;

$action-bar-height: 49px;

$side-nav-width: 16rem;
$side-nav-condensed-width: 2.75rem;
