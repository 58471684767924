.uni-card {
  @extend .uni-card-shadow;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: column;
  padding: 16px 24px;
  box-sizing: border-box;
  background-color: var(--sapBaseColor);
  border-radius: 16px;
  height: 100%;
  width: 100%;
  line-height: var(--sapContent_LineHeight);
  position: relative;
}

.uni-card-shadow {
  box-shadow: 0 0 2px rgba(255, 255, 255, 0.2), 0 2px 8px rgba(0, 0, 0, 0.3);
}
