%style-scroll {

  ::-webkit-scrollbar,
  &-tight::-webkit-scrollbar {
    width: 10px;
    height: 10px;

    &-track {
      box-shadow: 0 0px 0px 2px rgba(255, 255, 255, 0.2), 0px 2px 8px rgba(0, 0, 0, 0.3);
      border: var(--sapScrollbar_BorderColor);
    }
    &-thumb {
      cursor: pointer;
      background: var(--sapScrollBar_FaceColor);
    }
  }

  &-tight::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
}

uni-figure-svg.no-text .fd-illustrated-message__illustration {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

uni-figure-svg.large .fd-illustrated-message__illustration {
  use {
    transform: scale(3.5);
  }
}

uni-figure-svg.small .fd-illustrated-message__illustration {
  use {
    transform: scale(0.8);
  }
}

uni-figure-svg.tiny {
  margin-top: 0;
  .fd-illustrated-message__illustration {
    height: 60px;
    width: 45px;

    use {
      transform: scale(0.6);
    }
  }
}

uni-figure-svg.round .fd-illustrated-message__illustration {
  border-radius: 50%;
  transform: translateX(10px);
}

uni-figure-svg.error .fd-illustrated-message--dialog .fd-illustrated-message__illustration {
  margin-left: 55px; // because of the image on this specific case (error + dialog) we need to shift it so it will look centralized
}

uni-application-logo.small uni-figure-svg.no-text .fd-illustrated-message__illustration {
  margin: 0;
  padding: 0;
  width: 100%;

  use {
    transform: translate(-8px, -8px) scale(0.9);
  }
}
