@use "common/src/main" as common;

div.side-menu-popover-body {
  background-color: transparent !important;
  width: 450px !important;
  top: -50px !important;
  left: -(common.$sap-padding-large);
  transition: transform 0.2s;
  transform: translateX(0);
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  max-height: initial;

  &-higher {
    top: -45px !important;
  }

  &.fd-popover__popper--cdk-custom {
    background-color: transparent !important;
    box-shadow: none !important;
  }

  .fd-menu {
    background-color: transparent !important;
    max-width: 100%;
    width: 100% !important;
  }

  .fd-menu__list {
    background-color: transparent !important;
    box-shadow: none !important;
    max-width: initial;
  }
}

@media (max-width: common.$max-width-L) {
  div.side-menu-popover-body {
    left: -(common.$sap-padding-medium);
  }
}
