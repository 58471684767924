.uni-link {

  text-shadow: none !important;
  transition: none !important;

  &:focus {
    border: none;
  }

  &:focus,
  &:focus-visible {
    background-color: transparent !important;
    color: var(--sapLink_Active_Color) !important;
  }

  &:active {
    background-color: var(--fdLink_Focus_Background) !important;
    color: var(--fdLink_Text_Focus_Color) !important;
  }

  &:hover {
    cursor: pointer;
  }
}
