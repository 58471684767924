$stringColor: var(--sapIndicationColor_2);
$numberColor: var(--sapIndicationColor_6);
$booleanColor: var(--sapIndicationColor_4);
$objectColor: var(--sapIndicationColor_3);
$dateColor: var(--sapIndicationColor_7);

.fd-token {

  &:not(.fd-token--readonly) {
    padding: 0 0 0 8px !important;
  }
}

// Token borders by type
.field-token-horizontal-line {
  &.string, &.segment, &.purpose {
    border-top: 1px solid $stringColor;
  }

  &.number, &.integer, &.double, &.long, &.activityIndicator {
    border-top: 1px solid $numberColor;
  }

  &.boolean {
    border-top: 1px solid $booleanColor;
  }

  &.object, &.object-array {
    border-top: 1px solid $objectColor;
  }

  &.date, &.recurringDate {
    border-top: 1px solid $dateColor;
  }
}

fd-token {
  &.string, &.segment, &.purpose, &.milestoneJourneyId {
    .fd-token {
      border-color: $stringColor;
    }
  }

  &.number, &.integer, &.double, &.long, &.activityIndicator {
    .fd-token {
      border-color: $numberColor;
    }
  }

  &.boolean {
    .fd-token {
      border-color: $booleanColor;
    }
  }

  &.object, &.object-array {
    .fd-token {
      border-color: $objectColor;
    }
  }

  &.date, &.recurringDate {
    .fd-token {
      border-color: $dateColor;
    }
  }
}

uni-array-field{
  uni-tile.string-array, .horizontal-line.string-array, .array-field-token.string-array {
    border-color: $stringColor !important;
  }
  uni-tile.number-array, .horizontal-line.number-array, .array-field-token.number-array,
  uni-tile.integer-array, .horizontal-line.integer-array, .array-field-token.integer-array {
    border-color: $numberColor !important;
  }
  uni-tile.boolean-array, .horizontal-line.boolean-array, .array-field-token.boolean-array {
    border-color: $booleanColor !important;
  }
  uni-tile.object-array, .horizontal-line.object-array, .array-field-token.object-array {
    border-color: $objectColor !important;
  }
  uni-tile.date-array, .horizontal-line.date-array, .array-field-token.date-array {
    border-color: $dateColor !important;
  }
}
