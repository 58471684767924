@use "./app/components/var" as components;
@use "common/src/main" as common;
@import 'gridstack/dist/gridstack.min.css';
@import 'gridstack/dist/gridstack-extra.min.css';

$config: (
    "nav-bar-z-index": common.$main-nav-z-index,
    "nav-bar-expanded-z-index": common.$main-nav-expanded-z-index,
    "uni-loader-spinner-z-index": common.$loaders-z-index
);

@include components.components($config);
@include common.common($config);
