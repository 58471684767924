@mixin nav-bar-component($config) {
  $nav-bar-component-config: map-merge(
      ("nav-bar-z-index": null,
        "nav-bar-expanded-z-index": null),
      $config);

  .app-nav-bar {
    z-index: map-get($nav-bar-component-config, "nav-bar-z-index");

    &.expanded {
      z-index: map-get($nav-bar-component-config, "nav-bar-expanded-z-index");
    }
  }
}
