uni-connector-card,
uni-connector-card-tooltips,
lib-application-card,
.connector-card-tooltip {

  .disabled {
    color: var(--sapContent_DisabledTextColor);
  }

  .default-popover {
    padding: 5px 10px;
    background-color: var(--sapShellColor);
    border-radius: inherit;
  }

  .inner-info {
    display: flex;
    flex-direction: column;
    flex: 4;

    .title {
      font-size: 16px;
      color: var(--sapTextColor);
    }

    .icons {
      margin-top: 5px;
      display: flex;
    }

    &__content {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
    }
  }

  .icon {
    width: 24px;
    height: 24px;
    font-size: 16px;
    color: var(--sapTextColor);
    text-align: center;

    &__disabled {
      color: var(--sapContent_DisabledTextColor);
    }
  }

  .events-actions-list {
    min-width: 250px;
    max-width: 350px;
    max-height: 250px;
    overflow-y: auto;
    white-space: pre-line;

    & > li > span{
      display: inline-block;
    }
  }

  .popover-title {
    font-size: 16px;
  }

  .popover-list {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin-bottom: 0;
  }

  .popover-list-item {
    display: flex;
    white-space: pre-line;
  }

  %item-icon-base {
    font-size: 16px;
    font-weight: bold;
    width: 16px;
    color: var(--sapChart_OrderedColor_1);
    padding-right: 8px;
  }

  .sap-icon--dimension:before {
    @extend %item-icon-base;
  }

  .webhook-icon-tilda {
    @extend %item-icon-base;

    &::after {
      content: '~';
    }
  }
}
